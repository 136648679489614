import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import parser from "html-react-parser";

import BS from "assets/images/bs-desktop.png";
import BSWebp from "assets/images/bs-desktop.png?as=webp";
import BSMobile from "assets/images/bs-mobile.png";
import BSMobileWebp from "assets/images/bs-mobile.png?as=webp";

import CC from "assets/images/cc-desktop.png";
import CCWebp from "assets/images/cc-desktop.png?as=webp";
import CCMobile from "assets/images/cc-mobile.png";
import CCMobileWebp from "assets/images/cc-mobile.png?as=webp";

import { priceTable } from "../static-data";
import loadable from "@loadable/component";
import { SUPPORTED_REGIONS, CURRENCY_SIGN } from "../../shared/enums";

const ImageLoader = loadable(() => import("./common/ImageLoader"));

/**
 * @returns {JSX.Element}
 * @constructor
 */

const contentItems = [
  {
    additionalClass: "",
    baseColor: "255, 193, 64",
    mainHeading: () => (
      <>
        Save up to
        <strong>29%</strong>
      </>
    ),
    title: "Get ahead of allergy season. 🤧",
    titleAlt: "Get ahead of allergy season. 🤧",
    description:
      "Stop allergies in its tracks with the filter designed to tackle particulates like dust, pollen, and dander that make you a-a-achoo.",
    descriptionAlt:
      "Stop allergies in its tracks and take on particulates like dust, pollen, and dander that make you a-a-achoo.",
    image: {
      desktop: {
        defaultUrl: BS,
        webpUrl: BSWebp,
      },
      mobile: {
        defaultUrl: BSMobile,
        webpUrl: BSMobileWebp,
      },
      alt: "The mother of all filters.",
    },
  },
  {
    additionalClass: "hero--img-align",
    baseColor: "244, 45, 83",
    mainHeading: () => (
      <>
        Save up to <strong>31%</strong>
      </>
    ),
    title: "Put dander season behind you. 🤧",
    titleAlt: "Put dander season behind you.",
    description:
      "Take the animal out of your air with the filter that keeps your space free of allergens, funk, and dander.  ",
    descriptionAlt:
      "Take the animal out of your air with the filter that keeps your space free of funk, and dander.",
    image: {
      desktop: {
        defaultUrl: CC,
        webpUrl: CCWebp,
      },
      mobile: {
        defaultUrl: CCMobile,
        webpUrl: CCMobileWebp,
      },
      alt: "The mother of all filters.",
    },
  },
];

const productTitle = {
  BS: {
    milaOfferOne: () => "Mila Air with Big Sneeze filter ",
    milaOfferTwo: () => "Mila Air with Critter Cuddler filter ",
    milaRegular: () => "Mila Air",
  },
};
const regionalDiscount = {
  BS: {
    US: {
      single: "29%",
      multi: "31%",
      regular: "22%",
    },
    AU: {
      single: "29%",
      multi: "31%",
      regular: "22%",
    },
  },
  CC: {
    US: {
      single: "39%",
      multi: "45%",
      regular: "27%",
    },
    AU: {
      single: "39%",
      multi: "45%",
      regular: "27%",
    },
  },
};

const ContentItem = ({
  baseColor,
  mainHeading,
  title,
  description,
  image,
  hasBadge = false,
  additionalClass,
}) => {
  return (
    <div
      className={`hero hero--feb2024 hero--home-center hero--small ${
        hasBadge ? " hero--has-badge" : ""
      } ${additionalClass ? additionalClass : ""}`}
    >
      <div className="hero__holder">
        <div className="hero__header">
          <h1
            className="hero__title hero__title--small"
            style={{ "--color": `rgb(${baseColor})` }}
          >
            {mainHeading()}
          </h1>
          {hasBadge && (
            <div className="offer-badge">
              <div className="offer-badge__holder">
                <del className="offer-badge__original-price">$580</del>
                <ins className="offer-badge__discounted-price">$399</ins>
                <p className="offer-badge__message">
                  When you sign up for Auto-Refill
                </p>
              </div>
            </div>
          )}
        </div>
        <ImageLoader
          className="hero__image hero__image--2024fab  img-relative"
          placeholderAspectRatio={834 / 678}
          image={image.desktop.defaultUrl}
          webp={image.desktop.webpUrl}
          smallImage={image.mobile.defaultUrl}
          smallWebp={image.mobile.webpUrl}
          alt={image.alt}
        />
        <div className="hero__content">
          <h2 className="hero__subtitle hero__subtitle--alt">{title}</h2>
          <div className="hero__description hero__description--small">
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const isMobile = () => {
  return window.innerWidth <= 768;
};
const HeroWithPriceTableDefault = ({ localization }) => {
  const [currentColor, setCurrentColor] = useState(contentItems[0].baseColor);
  const [isMobileView, setIsMobileView] = useState(false);

  const { region } = localization;
  const regional = SUPPORTED_REGIONS[region] || SUPPORTED_REGIONS.US;
  const regionalBW = [SUPPORTED_REGIONS.US, SUPPORTED_REGIONS.AU].includes(
    SUPPORTED_REGIONS[region]
  );
  const regionalCA = [SUPPORTED_REGIONS.CA].includes(SUPPORTED_REGIONS[region]);
  const otherRegional = [
    SUPPORTED_REGIONS.SG,
    SUPPORTED_REGIONS.GB,
    SUPPORTED_REGIONS.EU,
  ].includes(SUPPORTED_REGIONS[region]);

  const { price } = priceTable.BS[regional];

  const awardSettings = {
    autoplay: true,
    speed: 600,
    cssEase: "cubic-bezier(0.65,0.05,0.36,1)",
    slidesToShow: 4,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    pauseOnFocus: false,
    arrows: false,

    responsive: [
      {
        mobileFirst: true,
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          mobileFirst: true,
        },
      },
    ],
  };
  const contentSettings = {
    autoplay: true,
    speed: 600,
    cssEase: "cubic-bezier(0.65,0.05,0.36,1)",
    slidesToShow: 1,
    autoplaySpeed: 6000,
    slidesToScroll: 1,
    pauseOnFocus: false,
    pauseOnHover: false,
    arrows: false,
    dots: true,
    beforeChange: (prevSlide, currentSlide) => {
      setCurrentColor(contentItems[currentSlide].baseColor);
    },
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

          infinite: true,
          mobileFirst: true,
        },
      },
    ],
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(isMobile());
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <section className="heroFlex">
        <div className="heroFlex__card heroFlex__card--default">
          <div className="awards awards--four">
            <div className="container">
              <Slider {...awardSettings} className="awards__slider">
                <blockquote className="awards__item">
                  <div className="awards__itemInner">
                    <h2>
                      ‘Best
                      <br />
                      Air Purifier’
                    </h2>
                    <cite>Popular Science</cite>
                  </div>
                </blockquote>
                <blockquote className="awards__item">
                  <div className="awards__itemInner">
                    <h2>
                      ‘A Truly Smart
                      <br />
                      Air Purifier’
                    </h2>
                    <cite>NYTimes/Wirecutter</cite>
                  </div>
                </blockquote>
                <blockquote className="awards__item">
                  <div className="awards__itemInner">
                    <h2>
                      ‘Best Smart
                      <br />
                      Air Purifier’
                    </h2>
                    <cite>Homes & Gardens</cite>
                  </div>
                </blockquote>
                <blockquote className="awards__item">
                  <div className="awards__itemInner">
                    <h2>
                      ‘Best Smart
                      <br />
                      Air Purifier’
                    </h2>
                    <cite>Gear Patrol</cite>
                  </div>
                </blockquote>
              </Slider>
            </div>
          </div>
          {regionalBW && (
            <Slider
              {...contentSettings}
              className="hero-content__slider"
              style={{ "--baseColor": `rgb(${currentColor})` }}
            >
              {contentItems.map(
                ({
                  baseColor,
                  mainHeading,
                  title,
                  titleAlt,
                  description,
                  descriptionAlt,
                  badgeText,
                  price,
                  image,
                  additionalClass,
                }) => (
                  <ContentItem
                    key={title}
                    baseColor={baseColor}
                    mainHeading={mainHeading}
                    title={isMobileView ? titleAlt : title}
                    description={isMobileView ? descriptionAlt : description}
                    badgeText={badgeText}
                    price={price}
                    image={image}
                    localization={localization}
                    additionalClass={additionalClass}
                  />
                )
              )}
            </Slider>
          )}
          {regionalCA && (
            <div
              className="hero-content__slider"
              style={{ "--baseColor": `rgb(${contentItems[1].baseColor})` }}
            >
              <ContentItem
                key={contentItems[1].title}
                baseColor={contentItems[1].baseColor}
                mainHeading={contentItems[1].mainHeading}
                title={
                  isMobileView
                    ? contentItems[1].titleAlt
                    : contentItems[1].title
                }
                description={
                  isMobileView
                    ? contentItems[1].descriptionAlt
                    : contentItems[1].description
                }
                badgeText={contentItems[1].badgeText}
                price={contentItems[1].price}
                image={contentItems[1].image}
                localization={localization}
                hasBadge={true}
                additionalClass={contentItems[1].additionalClass}
              />
            </div>
          )}
          {otherRegional && (
            <div
              className="hero-content__slider"
              style={{ "--baseColor": `rgb(${contentItems[1].baseColor})` }}
            >
              <ContentItem
                key={contentItems[1].title}
                baseColor={contentItems[1].baseColor}
                mainHeading={contentItems[1].mainHeading}
                title={
                  isMobileView
                    ? contentItems[1].titleAlt
                    : contentItems[1].title
                }
                description={
                  isMobileView
                    ? contentItems[1].descriptionAlt
                    : contentItems[1].description
                }
                badgeText={contentItems[1].badgeText}
                price={contentItems[1].price}
                image={contentItems[1].image}
                localization={localization}
                hasBadge={false}
                additionalClass={contentItems[1].additionalClass}
              />
            </div>
          )}
        </div>
      </section>
      <div
        className="price-table price-table--color-inherit"
        style={{ "--color": "255, 193, 64" }}
      >
        <div className="container">
          {regionalBW && (
            <>
              <div className="price-table__grid">
                <div className="price-table__column price-table__column--offer">
                  <h3
                    className="price-table__column-title"
                    style={{ opacity: "0" }}
                  >
                    Home Sale 🏡
                  </h3>
                  <div className="price-table__inner-grid">
                    <div className="hero-price-card price-card--offer">
                      <div className="price-card__info">
                        <div className="price-card__info-column">
                          <strong className="price-card__count">
                            {parser(productTitle.BS.milaOfferOne())}
                          </strong>
                          <strong className="price-card__discount">
                            Save up to {regionalDiscount.BS[regional].single}
                          </strong>
                        </div>
                        <div className="price-card__info-column">
                          <strong className="price-card__amount">
                            {`${CURRENCY_SIGN[regional]}${price.offer.singleUnit}`}{" "}
                            <span>with Auto-Refill</span>
                          </strong>
                          <span className="price-card__old-price">
                            <s>{`${CURRENCY_SIGN[regional]}${
                              price.range.single.min
                            } ${
                              price.range.single.max
                                ? `to ${CURRENCY_SIGN[regional]}${price.range.single.max}`
                                : ""
                            }`}</s>{" "}
                            without Auto-Refill
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="hero-price-card price-card--offer">
                      <div className="price-card__info">
                        <div className="price-card__info-column">
                          <strong className="price-card__count">
                            {parser(productTitle.BS.milaOfferTwo())}
                          </strong>
                          <strong className="price-card__discount">
                            Save up to {regionalDiscount.BS[regional].multi}
                          </strong>
                        </div>
                        <div className="price-card__info-column">
                          <strong className="price-card__amount">
                            {`${CURRENCY_SIGN[regional]}${price.offer.mutipleUnits}`}{" "}
                            <span>with Auto-Refill</span>
                          </strong>
                          <span className="price-card__old-price">
                            <s>{`${CURRENCY_SIGN[regional]}${
                              price.range.multi.min
                            } ${
                              price.range.multi.max
                                ? `to ${CURRENCY_SIGN[regional]}${price.range.multi.max}`
                                : ""
                            }`}</s>{" "}
                            without Auto-Refill
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="price-table__column price-table__column--regular">
                  <h3 className="price-table__column-title">
                    Everyday Pricing
                  </h3>
                  <div className="hero-price-card price-card--regular">
                    <div className="price-card__info">
                      <div className="price-card__info-column">
                        <strong className="price-card__count">
                          {parser(productTitle.BS.milaRegular())}
                        </strong>
                        <strong className="price-card__discount">
                          Save up to {regionalDiscount.BS[regional].regular}
                        </strong>
                      </div>
                      <div className="price-card__info-column">
                        <strong className="price-card__amount">
                          {`${CURRENCY_SIGN[regional]}${price.regular}`}{" "}
                          <span>with Auto-Refill</span>
                        </strong>
                        <span className="price-card__old-price">
                          <s>{`${CURRENCY_SIGN[regional]}${price.range.regular.min} to ${CURRENCY_SIGN[regional]}${price.range.regular.max}`}</s>{" "}
                          without Auto-Refill
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="price-table__button">
                <a
                  href="https://shop.milacares.com"
                  className="btn btn--red btn--medium"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Start Saving
                </a>
              </div>
            </>
          )}
          <div className="price-table__footer">
            <h3 className="price-table__title">The Mila Promise</h3>
            <ul className="price-table__list">
              <li>
                <svg className="icon icon-money-back-guarantee">
                  <use xlinkHref="#icon-money-back-guarantee" />
                </svg>
                30 day Mila-backed guarantee
              </li>
              <li>
                <svg className="icon icon-shipping">
                  <use xlinkHref="#icon-shipping" />
                </svg>
                Fast free shipping with same/next business day shipping
              </li>
              <li>
                <svg className="icon icon-warranty">
                  <use xlinkHref="#icon-warranty" />
                </svg>
                One year warranty
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroWithPriceTableDefault;
